import React from 'react';
import styles from './styles/App.module.css';

const Paragraph = () => (
    <div className={styles.paragraph}>
        Starting from Feb 3rd, 2023, please submit your localization requests via the <a href="https://paypal.sharepoint.com/sites/WorldReadyLocalization/SitePages/How-to-Request-Translations-in-GlobaLink.aspx">new portal available here</a>.
    </div>
);

export default Paragraph;
