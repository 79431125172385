import React from 'react';
import styles from './styles/App.module.css';
import Paragraph from "./Paragraph";

const App = () => {
  return (
    <div className={styles.landing}>
      <div className={styles.section}>
        <Paragraph />
      </div>
    </div>
  );
}

export default App;
